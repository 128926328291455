import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, from} from 'rxjs';
import {map} from 'rxjs/operators';
import {mesas} from '../model/mesas.interface';
import { Constants } from '../globals';
@Injectable({
  providedIn: 'root'
})
export class MesasService {
  direccion = Constants.API_URL;
  private url: string = '';  
  constructor(private http: HttpClient) { }

   searchProducts(id :string){
    this.url = this.direccion+`mesasPorId/${encodeURI(id)}/${encodeURI(localStorage.getItem("idSociedad"))}`;
    
    return this.http.get<mesas>(this.url).pipe();
  }

  getDetailsProductos(id:string){
    
   
    return this.http.get<mesas>(this.direccion+`mesasPorId/${encodeURI(id)}/${encodeURI(localStorage.getItem("idSociedad"))}`).pipe();
    
  }

  buscarPorNumMesa(numMesa:string){
    
   
    return this.http.get<mesas>(this.direccion+`mesasPorNumMesaSociedad/${encodeURI(numMesa)}/${encodeURI(localStorage.getItem("idSociedad"))}`).pipe();
    
  }



}
