import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, from} from 'rxjs';
import {map} from 'rxjs/operators';
import {Sociedades} from '../model/sociedades.interface';
import { Constants } from '../globals';
@Injectable({
  providedIn: 'root'
})
export class SociedadesService {
  direccion = Constants.API_URL;
  private url: string = '';  
  constructor(private http: HttpClient) { }

   searchProducts(nombre :string){
    this.url = this.direccion+`sociedadesPorNombre/${encodeURI(nombre)}`;
    
    return this.http.get<Sociedades>(this.url).pipe();
  }

  getDetailsProductos(id:string){
    
   
    return this.http.get<Sociedades>(this.direccion+`sociedadesPorId/${encodeURI(id)}`).pipe();
    
  }



}
