import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, from} from 'rxjs';
import {map} from 'rxjs/operators';
import {Usuarios} from '../model/Usuarios';
import { Constants } from '../globals';
@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  direccion = Constants.API_URL;
  private url: string = '';  
  constructor(private http: HttpClient) { }

   searchProducts(nombre :string){
    this.url = this.direccion+`usuariosPorNombre/${encodeURI(localStorage.getItem("idSociedad"))}/${encodeURI(nombre)}`;
    
    return this.http.get<Usuarios>(this.url).pipe();
  }

  searchProductsAdmin(nombre :string){
    this.url = this.direccion+`usuariosPorNombreAdmin/${encodeURI(localStorage.getItem("idSociedad"))}/${encodeURI(nombre)}`;
    
    return this.http.get<Usuarios>(this.url).pipe();
  }
  getDetailsProductos(id:string){
    
   
    return this.http.get<Usuarios>(this.direccion+`usuariosPorId/${encodeURI(id)}`).pipe();
    
  }



}
