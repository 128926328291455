import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {category} from '../model/category.interface';
import { Observable } from 'rxjs';
import { Constants } from '../globals';
import { Categorias } from '../model/categorias';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  direccion = Constants.API_URL;
  private url: string = this.direccion+`categoria/${encodeURI(localStorage.getItem("idSociedad"))}`;  
  httpclient: any;
  constructor(private http: HttpClient) { }

  getCategories(url): Observable<any> {
    return this.httpclient.get(this.url);
  }
  listCategorias: category[];
  searchCategory(){
    
    return this.getCategories(this.url).subscribe(
      data => {
        this.listCategorias = data;
        
       
      }
    );
  }

  buscarCategoriasNombre(nombre :string){
    this.url = this.direccion+`categoriasPorNombre/${encodeURI(localStorage.getItem("idSociedad"))}/${encodeURI(nombre)}`;
    
    return this.http.get<Categorias>(this.url).pipe();
  }
}
